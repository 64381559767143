import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  HalfWidthBlock,
  Medailon,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"
import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"

import Logo from "../../../images/reference/hvezdarna/logohvezdarna.png"
import Top from "../../../images/reference/hvezdarna/top.jpg"
import Header from "../../../images/reference/hvezdarna/header-marketing.png"
import Dusek from "../../../images/reference/hvezdarna/reaction-marketing.png"

class Hvezdarna extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `linear-gradient(rgba(24,25,26,1.0),rgba(2,3,4,0.6)),url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>
            Reference na marketingové práce pro Hvězdárnu a Planetárium Brno |
            igloonet
          </title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>

        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          extodkaz="//hvezdarna.cz/"
          heading="Reference na marketingové práce pro Hvězdárnu a Planetárium Brno"
          odstavec="listopad - prosinec 2015"
        >
          <ReferenceBannerContent
            image={{
              src: Header,
            }}
            mainText="Hvězdárna a planetárium Brno je centrum pro popularizaci vědy ve srozumitelné, zábavné a interaktivní podobě. Každý rok do ní zavítá více než 100 000 návštěvníků. Jejím zřizovatelem je statutární město Brno."
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Zadání</TitleH2>
              <p className="text-center">
                Zvýšit celkové povědomí o Hvězdárně mezi širokou veřejností.
              </p>
            </PaddingWrapper>

            <PaddingWrapper>
              <TitleH2>Rozsah projektu</TitleH2>
              <p>
                propagace unikátního obsahu na sociálních sítích; intenzivní
                videokampaň; ucelená komunikační kampaň napříč Facebookem,
                Twitterem a Instagramem
              </p>
            </PaddingWrapper>

            <PaddingWrapper>
              <TitleH2>Zhodnocení zadavatele</TitleH2>
              <p>
                Rychlé a efektivní jednání, samostatnost, ale také nezbytná
                zpětná vazba. Nemohu si stěžovat, takto si představuji
                spolupráci ve světě sociálních sítí.
              </p>
            </PaddingWrapper>
          </HalfWidthBlock>

          <Medailon
            wide
            image={Dusek}
            alt=""
            text="Rychlé a efektivní jednání, samostatnost. Takto si představuji spolupráci."
            name="Jiří Dušek"
            position="ředitel"
          />
        </ReferenceContainer>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Hvezdarna
